import React, { useState, useEffect } from 'react'
import { ethers } from 'ethers'
import { keccak256 } from 'ethers/lib/utils';
import keiino from './contracts/artifacts/keiino.json';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import styled from "styled-components";
import { initWeb3Onboard } from './services'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";

import {
  useAccountCenter,
  useConnectWallet,
  useNotifications,
  useSetChain,
  useWallets,
  useSetLocale
} from '@web3-onboard/react'
import './App.css'
import { borderLeftColor } from '@mui/system';


let provider

const NFTcontractAddress = "0x4b18450660CC30db638247405C6F2c523A02E049";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress sx={{ height: '15px', borderRadius: "30px", background: "#0073ff3b" }} variant="determinate" {...props} />
      </Box>

    </Box>
  );
}
LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};



function MintPage() {

  const [{ wallet }, connect, disconnect, updateBalances, setWalletModules] =
    useConnectWallet()
  const [notifications, customNotification, updateNotify] = useNotifications()
  const connectedWallets = useWallets()
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [web3Onboard, setWeb3Onboard] = useState(null)

  const [error, setError] = useState('');
  const [errCode, setErrCode] = useState(0);
  const [data, setData] = useState({});
  const [WL, setWL] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [accountCenterPosition, setAccountCenterPosition] = useState('topRight')
  const [notifyPosition, setNotifyPosition] = useState('bottomRight')
  const [locale, setLocale] = useState('en')
  const [accountCenterSize, setAccountCenterSize] = useState('normal')
  const [accountCenterExpanded, setAccountCenterExpanded] = useState(false)

  const [progress, setProgress] = React.useState(10);

  function toggleMenu() {
    const menu = document.getElementById("mobileNavContainer");
    menu.classList.toggle("open-menu");
    console.log("pressed");
  }

  useEffect(() => {
    setWeb3Onboard(initWeb3Onboard);
  }, [])

  useEffect(() => {
    console.log(notifications)
  }, [notifications])

  useEffect(() => {
    if (!connectedWallets.length) return

    const connectedWalletsLabelArray = connectedWallets.map(
      ({ label }) => label
    )
    window.localStorage.setItem(
      'connectedWallets',
      JSON.stringify(connectedWalletsLabelArray)
    )


  }, [connectedWallets, wallet])



  useEffect(() => {
    const previouslyConnectedWallets = JSON.parse(
      window.localStorage.getItem('connectedWallets')
    )

    if (previouslyConnectedWallets?.length) {
      async function setWalletFromLocalStorage() {
        await connect({ autoSelect: previouslyConnectedWallets[0], disableModals: true })
      }
      setWalletFromLocalStorage()
    }
  }, [web3Onboard, connect])


  useEffect(() => {
    fetchData();
  }, []);


  async function fetchData() {
    if (typeof window.ethereum !== 'undefined') {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(NFTcontractAddress, keiino.abi, provider);

      try {
        let cost;

        const totalSupply = await contract.totalSupply();
        const step = await contract.sellingStep();
        if (step == 1) {
          cost = await contract.publicSalePrice();
          document.getElementById("public").classList.add("active");
        }

        const object = { "cost": String(cost), "totalSupply": String(totalSupply), "step": String(step) }
        setData(object);

      }
      catch (err) {
        setError(err.message);
      }
    }
  }

  async function mint() {
    if (typeof window.ethereum !== 'undefined') {
      let accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(NFTcontractAddress, keiino.abi, signer);
      try {
        let overrides = {
          from: accounts[0],
          value: String(data.cost * quantity)
        }
        const transaction = await contract.publicSaleMint(accounts[0], quantity, overrides);
        await transaction.wait();
        fetchData();
      }
      catch (err) {
        setError(err.data);
        handleError(err.data);
      }
    }
  }
  async function handleError(err) {
    if (err?.message?.includes("MetaMask Tx Signature: User denied transaction signature.") || err?.message?.includes("user rejected transaction")) {
      console.log('User denied the transaction signature.');
      setErrCode(1);
      handleShow();
    }
    if (err?.message?.includes("insufficient funds")) {
      console.log('Insufficient funds');
      setErrCode(2);
      handleShow();
    }





  }
  function valueUp() {
    if (quantity < 10) {
      setQuantity(quantity + 1);
    }
  }
  function valueDown() {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  }

  return (
    <main>
      <div id="mobileNavContainer" className="mobile-nav">
        <div className="mobile-nav-close-button">
          <img src="/icons/close.svg" alt="" onClick={toggleMenu} />
        </div>
        <ul>

          <li>
            <img className="mobile-nav-logo" src="/logo.png" alt="" />
          </li>
          <li>
            <a href="/#mint" onClick={toggleMenu}>
              Mint
            </a>
          </li>
          <li>
            <a href="/#introduction" onClick={toggleMenu}>
              About
            </a>
          </li>
          <li>
            <a href="/#faq" onClick={toggleMenu}>
              FAQ
            </a>
          </li>

          <li>
            <div className="social-icons">
              <a href="https://twitter.com/keiinoband" target="_blank">
                <img className="nav-social" src="/icons/twitter.svg" alt="" />
              </a>
              <a href="https://www.instagram.com/keiinoband/" target="_blank">
                <img className='nav-social' src="/icons/instagram.svg" alt="" />
              </a>
            </div>
            <a href="https://www.youtube.com/KEiiNOmusic" target="_blank">
              <img className='nav-social' src="/icons/youtube.svg" alt="" />
            </a>
            <a href="https://www.keiino.com/keiinoworld/" target="_blank">
              <img className='nav-social' src="/icons/web.png" alt="" />
            </a>
          </li>
        </ul>
      </div>
      <div className="mobile-menu-button" onClick={toggleMenu}>
        <img src="/icons/menu.svg" alt="" />
      </div>
      <nav className="navbar">
        <div className="nav-container">
          <img src="logo.png" className='nav-logo' alt="" />
          <a className="hide-800" href="/#mint">
            Mint
          </a>
          <a className="hide-800" href="/#introduction">
            About
          </a>
          <a className="hide-800" href="/#faq">
            FAQ
          </a>
          <div className="social-icons hide-800">
            <a href="https://twitter.com/keiinoband" target="_blank">
              <img className="nav-social" src="/icons/twitter.svg" alt="" />
            </a>
            <a href="https://www.instagram.com/keiinoband/" target="_blank">
              <img className='nav-social' src="/icons/instagram.svg" alt="" />
            </a>
            <a href="https://www.youtube.com/KEiiNOmusic" target="_blank">
              <img className='nav-social' src="/icons/youtube.svg" alt="" />
            </a>
            <a href="https://www.keiino.com/keiinoworld/" target="_blank">
              <img className='nav-social' src="/icons/web.png" alt="" />
            </a>
          </div>

        </div>
      </nav>

      <div className='banner' id="mint">
        <section className="main">
          <div className="phases">
            <div className="phase" id="public">
              public sale
            </div>
          </div>
          <div className="main-content">
            <img src="assets/preview.gif" className='preview' ></img>

            <div className="containerr onboard">
              <div className="dates">
                <div className="date">
                  Date
                </div>
                <div className="datetext">
                  &nbsp;- 23 Nov 2022
                </div>
              </div>
              <h2>KEiiNO - NFT Launch</h2>
              {wallet && (
                <div className="network-select">

                </div>
              )}

              <div className="flexcenter">
                {!wallet && (
                  <button
                    className="mintbutton"
                    onClick={() => {
                      connect()
                    }}
                  >
                    CONNECT
                  </button>
                )}
                {wallet && (
                  <div className="minting">
                    {data.step != 0 ? (<>
                      <div className="cost">
                        <h2>Price</h2>
                        <h3>{data.cost / 10 ** 18} <span>MATIC</span></h3>
                      </div>

                      <div className='progress'>
                        <h3 className="minted">Total minted &nbsp;({data.totalSupply} / 999)</h3>
                        <Box sx={{ width: '100%', height: '60px' }}>
                          <LinearProgressWithLabel value={(data.totalSupply * 100 / 999)} />
                        </Box>
                      </div>
                    </>
                    ) : (
                      <div>
                        <h3>Sale has not started yet.</h3>
                      </div>
                    )
                    }


                    <br></br>
                    <br></br>


                    {data.step == 1 &&
                      <div className="flexcenter">
                        <div className='quantitymint'>
                          <h2>Quantity (MAX 10 per Transaction)</h2>
                          <input
                            type="number"
                            id="quantity"
                            min="1"
                            max="10"
                            step="1"
                            value={quantity}
                          />
                          <div className="quantitybuttons">
                            <div className="arrowup" onClick={valueUp}></div>
                            <div className="arrowdown" onClick={valueDown}></div>
                          </div>
                        </div>
                        <button className="mintbutton" onClick={mint}>MINT</button>
                      
                      </div>}
                     
                  </div>
                  
                )}
                 <CrossmintPayButton className="creditbuy"
                          clientId="34f1233e-6355-426e-9a7c-5114cd104f68"
                          mintConfig={{ "type": "erc-721", "totalPrice": "20", "_quantity": 1}}

                        />
              </div>

              {errCode == 1 &&

                <Modal
                  show={show}
                  onHide={handleClose}
                  backdrop="static"
                  keyboard={false}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Error</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    You rejected the transaction. Try minting again.
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Close
                    </Button>

                  </Modal.Footer>
                </Modal>

              }
              {errCode == 2 &&

                <Modal
                  show={show}
                  onHide={handleClose}
                  backdrop="static"
                  keyboard={false}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Error</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    You don't have enough funds.
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Close
                    </Button>

                  </Modal.Footer>
                </Modal>

              }
              {errCode == 3 &&

                <Modal
                  show={show}
                  onHide={handleClose}
                  backdrop="static"
                  keyboard={false}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Error</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    You can only get 1 free.
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Close
                    </Button>

                  </Modal.Footer>
                </Modal>

              }

            </div>

          </div>

        </section>


      </div>

      <section id="introduction">
        <img src="/assets/nextsection.jpg" className='introdback' alt="" />
        <div className="introduction">
          <div className="introduction-content">
            <img src="logo.png" alt="" />
            <h2>About</h2>
            <p>
              Reaching a broad audience since their debut in 2019, the 5x platinum-awarded trio has made it to #1 on the Norwegian sales-charts, #2 on Spotify’s Gloabal Viral Chart, 150 million combined streams and several notable music-awards won. KEiiNO will be offering NFT-art created by artists in their community and external collaborations as well as a new set of unique, personalized fan experiences. This includes access to the exclusive experiences facilitated decentrally using NFTs, and a share of the band and brand's growth. Get your NFT above!
            </p>
            <h2>Benefits</h2>
            <p>

              Each NFT minted grants fans access to their personal limited edition artwork of the KEiiNO spirit animals – the snow fox, the reindeer and the wolf. NFT owners unlock additional benefits, which are to be announced in the upcoming weeks.

            </p>
          </div>
          <img src="assets/secpic.jpg" alt="" />
        </div>

      </section>
      <div className="faq" id="faq">
        <h2>FAQ</h2>
        <Accordion>

          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>How can I buy an NFT?</Typography>
            <div className="rgb2"></div>

          </AccordionSummary>
          <AccordionDetails>
            <div className="rgb2"></div>

            <Typography>
              1. Log into your Metamask or Coinbase wallet which holds some MATIC tokens, or download the extension in your internet browser. <br />
              2. Connect to your wallet. <br />
              3. Click on the mint button and you will be prompted to sign your transaction. <br />
              4. Once you have made the purchase, your KEiiNO NFT will appear in your wallet and also on select secondary marketplaces!
            </Typography>
          </AccordionDetails>

        </Accordion>
        <Accordion>

          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Can I resell my NFT?</Typography>
            <div className="rgb2"></div>

          </AccordionSummary>
          <AccordionDetails>
            <div className="rgb2"></div>

            <Typography>
              Yes, you can list your NFT on secondary marketplaces like Opensea and sell it to another NFT collector.
            </Typography>
          </AccordionDetails>

        </Accordion>
        <Accordion>

          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Where can I see my purchased NFT?</Typography>
            <div className="rgb2"></div>

          </AccordionSummary>
          <AccordionDetails>
            <div className="rgb2"></div>

            <Typography>
              Your KEiiNO NFT will appear in any linked wallet you used to make your purchase. You can also see your freshly minted NFT directly on your Opensea account after a short time period.
            </Typography>
          </AccordionDetails>

        </Accordion>
        <Accordion>

          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>What are the secondary royalties?</Typography>
            <div className="rgb2"></div>

          </AccordionSummary>
          <AccordionDetails>
            <div className="rgb2"></div>

            <Typography>
              The royalty fee for reselling is set to 7,5%, which is considered industry standard. Nowadays, sellers have a choice to pay royalties, depending on the secondary marketplace used.
            </Typography>
          </AccordionDetails>

        </Accordion>

      </div>


      <footer>
        <h1>Copyright 2022 KEiiNO AS</h1>

      </footer>


    </main>
  )
}

export default MintPage
