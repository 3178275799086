import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MintPage from "./MintPage";
ReactDOM.render(
    <BrowserRouter>
    <Routes>
        <Route path="/" element={<MintPage />} />
    </Routes>
  </BrowserRouter>,
    document.getElementById("root")
  );
